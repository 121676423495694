<template>
  <div class="box form">
    <h2 class="head">Leave your message</h2>
    <div class="body">
        <form @submit.prevent="handleSubmit" class="row form-group">
        <input
          type="text"
          placeholder="Your name"
          class="form-control p-2 my-2"
          v-model="fields.name"
          required
        />
        <input
          type="email"
          placeholder="Your e-mail"
          class="form-control p-2 my-2"
          v-model="fields.email"
          required
        />
        <input
          type="text"
          placeholder="Subject"
          class="form-control p-2 my-2"
          v-model="fields.subject"
          required
        />
        <textarea
          name="message"
          placeholder="Type a message"
          class="form-control p-2 my-2"
          rows="4"
          v-model="fields.text"
          required
        ></textarea>
        <button type="submit" class="btn btn-success form-control my-4">
          SEND
        </button>
      </form>
    </div>
    <transition name="fade">
      <Notify v-if="message" :message="message" @close="message = null" />
    </transition>
  </div>
</template>

<script>
import Notify from './Notify.vue'
import firebase, { serverTimestamp } from '../../firebase'
import api from "@/api";
export default {
  components: {
    Notify
  },
  data() {
    return {
      fields: {
        name: '',
        email: '',
        subject: '',
        text: ''
      },
      message: null
    };
  },
  methods: {
    async handleSubmit() {
      this.message = { text: 'sending...', type: 'loading', close: false }
      api
        .post("mail", { ...this.fields})
        .then(({ data }) => {
          console.log(data)
          this.fields = { name: '', email: '', subject: '', text: ''},
          this.message = { text: 'Successful', type: 'success', close: true }
        })
        .catch((err) => this.message = { text: err.message, type: 'danger', close: true })

      firebase.firestore().collection("messages").add({ ...this.fields, createAt: serverTimestamp() })
        .then((docRef) => console.log("Document written with ID: ", docRef.id))
        .catch((error) => console.error("Error adding document: ", error));
    },
  },
};
</script>

<style scoped>

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.box .head {
  height: 10vh;
}

.box .body {
  width: 80vw;
  max-width: 380px;
  min-height: 40vh;
}

input:focus, textarea {
  box-shadow: none !important;
}

textarea {
  resize: none;
}

.fade-enter-active {
  animation: fade-anim .5s ease-in-out forwards;
}

.fade-leave-active {
  animation: fade-anim .5s ease-in-out reverse;
}

@keyframes fade-anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
<template>
    <div 
        class="notify alert alert-dismissible" 
        :class="{
            'alert-success': message.type === 'success',
            'alert-danger': message.type === 'danger',
            'alert-warning': message.type === 'warning',
            'alert-warning': message.type === 'loading'
        }"
    >
        <button v-if="message.close" type="button" class="btn-close" data-bs-dismiss="alert" @click="$emit('close')"></button>
        {{ message.text }}
        <!-- <div v-show="message.type === 'loading'" class="spinner-border text-light"></div> -->
    </div>
</template>

<script>
export default {
    props: [
        'message'
    ],
    watch: {
        message(val) {
            if(val.close) setTimeout(() => this.$emit('close'), 2800)
        }
    }
}
</script>

<style scoped>
.notify {
    position: fixed;
    bottom: 2.4em;
    left: 1em;
    width: 350px;
}

button {
    box-shadow: none !important;
}

@media only screen and (max-width: 800px) {
    .notify {
        top: 1em;
        right: 1em;
        left: 1em;
        width: auto;
        bottom: auto;
    }
}
</style>
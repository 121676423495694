<template>
  <div>
    <nav-bar>
      <router-link :to="{ name: 'Home' }" class="btn btn-primary px-4">HOME</router-link>
    </nav-bar>
    <section>
      <div class="title text-center p-1">
        <h1>CONTACT ME</h1>
      </div>
      <div class="content">
        <Infos />
        <Form />
      </div>
    </section>
  </div>
</template>

<script>
import Infos from "@/components/contact/Infos.vue";
import Form from "@/components/contact/Form.vue";
import NavBar from '../../components/main/NavBar.vue';
export default {
  components: { Infos, Form, NavBar },
};
</script>

<style scoped>
section {
  display: block;
  min-height: calc(100vh - 120px);
}

section .content {
  justify-content: space-evenly;
}

@media only screen and (max-width: 800px) {
  section .content {
    flex-direction: column;
  }

  .title h1 {
    display: none !important;
  }
}
</style>